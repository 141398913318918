@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");
body {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
}

div:focus,
input:focus,
button:focus {
    outline: none;
}

html {
    scroll-behavior: smooth;
}

.bg-red {
    background-color: red;
}

/* Carousel animation */
.CarouselSlide {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/* End of carousel animation */

/* Timeline css */
.timeline-c {
    padding-left: 4.5%;
}
.timeline {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 6rem;
    padding-bottom: 6%;
}
.timeline:before {
    display: inline-block;
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: #001242;
    border: 2px solid white;
    z-index: 1;
}
.timeline:after {
    content: "";
    border-left: 3px solid #001242;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0.55rem;
}
.timeline:last-child:after {
    content: none;
}
.timeline h3 {
    margin-top: 0.1%;
    font-size: 1.2vw;
    font-weight: bold;
    padding-bottom: 2%;
}
.timeline p {
    font-weight: 400;
    font-size: 1vw;
}
@media (max-width: 450px) {
    .timeline {
        padding-left: 11vw;
    }
    .timeline h3 {
        font-size: 4.5vw;
    }
    .timeline p {
        font-size: 4vw;
    }
}
/* Timeline end */

/* Horizontal timeline */
/* body{
    background: #dfe6e9;
    padding: 50px 0px;
    margin: 0px 0px;
  } */

.timeline-content{
    border: 1px solid black;
    margin: 20px;
}

.timelineh {
    width: 100%;
    margin: 0 auto;
    display: block;
}

ul#timelineh {
    list-style: none;
    position: relative;
    display: flex;
    height: 100%;
    margin: 0px auto;
    padding: 0;
}

ul#timelineh:before {
    content: "";
    height: 1px;
    top: 200px;
    right: 0;
    width: 100%;
    margin-bottom: 20px;
    background: black;
    position: absolute;
}

ul#timelineh li {
    display: table-cell;
    float: none !important;
    margin: 0 auto;
    /*height: 27vw;*/
    vertical-align: bottom;
    text-align: start;
    cursor: pointer;
    flex: 1;
    border-right: 1px solid black;
}

.timeline-color{
    width: 25px;
    height: auto;
    z-index: 1;
    background-color: #FFDBDB;
}

.item-wrp{
    padding: 45px 14px 0;
}

ul#timelineh li:last-child .item-wrp .details {
    /* right: 0' */
    position: absolute;
    top: 215px;
}

ul#timelineh li:last-child .item-wrp .border {
    position: absolute;
}

ul#timelineh li .item-wrp {
    top: 30px;
}

ul#timelineh li:nth-child(even) .item-wrp {
    top: -70px;
}
ul#timelineh li:nth-child(odd) .item-wrp {
    top: -70px;
}

ul#timelineh li .border {
    width: 1px;
    height: 65px;
    background: black;
    margin: 35px;
    position: relative;
}

ul#timelineh li .border:after {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 3px solid black;
    background-color: black;
    position: absolute;
    bottom: 0;
    left: -7px;
    top: 19px;
}

.timeline-color-pf{
    background: #DBFCF7;
    margin-left: -12px;
    padding: 0 5px;
    width: 12vw;
}
.timeline-color-pf h4{
    width: 9vw;
}

.timeline-color-camb{
    background: #DBFCF7;
    margin-left: -12px;
    padding: 0 5px;
    width: 13vw;
}

.timeline-color-camb h4{
    width: 8vw;
}


ul#timelineh li:nth-child(even) .border:after {
    top: -12px;
}
ul#timelineh li:nth-child(odd) .border:after {
    top: -12px;
}

.timeline-ox{
    background-color: #DBFCF7;
    width: auto;
    height: auto;
    padding: 0 22px;
    margin-left: -30px;
    position: absolute;
}

ul#timelineh li .title p {
    margin: 0 0;
    font-size: 45px;
    font-weight: 800;
    margin-left: 1.4vw;
    color: #107fe8;
}
ul#timelineh li .details {
    width: 150px;
    text-align: left;
}
ul#timelineh li .details .blue-back {
    font-weight: 600;
    color: #107fe8;
    background-color: #ecf8ff;
    text-align: center;
    padding: 10px;
    border-radius: 500px;
}
ul#timelineh li .details h4 {
    text-decoration: underline;
    font-size: 16px;
}
ul#timelineh li .details .third {
    font-weight: 600;
    font-size: 18px;
    width: 12vw;
}
/* End of horizontal timeline */

.iBetaDocme > input {
    background-color: #eff0f0;
}

.iBetaDocme > .PhoneInputCountry {
    background-color: #eff0f0 !important;
}

/* new timeline */
.dot {
    content: "";
    display: block;
    width: 0vw;
    height: 1px;
    border-radius: 50%;
    border-left: 10vw solid black;
    background-color: black;
}
/* 
.line {
    content: "";
    height: 1px;
    top: auto;
    left: 42px;
    right: 0;
    width: 10%;
    margin-bottom: 20px;
    background: black;
    position: absolute;
} */

.dateDetails {
    color: #107fe8;
    background-color: #ecf8ff;
    text-align: center;
    padding: 12px;
    border-radius: 500px;
}

/* new timeline END */

/* Beta form */
@media (max-width: 450px) {
    #lBeta {
        font-size: 10px !important;
        margin: 1.5vw 0vw;
    }
    .iBeta {
        height: 35px !important;
        font-size: 4vw !important;
    }
    .PhoneInputInput {
        height: 35px !important;
        font-size: 4vw !important;
    }
    .PhoneInput {
        margin-bottom: 7vw;
    }
    .sliderContainer {
        width: 80vw !important;
    }
    .sliderContainer h3 {
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }
    .sliderContainer p {
        font-size: 13px !important;
    }
    .docmePartnerSlider .slick-slide img{
        width: 100%;
        height: 100%;
    }
    .docmePartnerSlider .slick-dots li button:before {
        font-size: 0.6rem !important;
        /* color: black; */
    }
    .docmePartnerSlider .slick-dots{
        margin-top: 10vw;
    }
    .docmeSliderSlick {
        width: 95vw !important;
        display: flex;
        justify-content: center;
    }
}
/* End of beta form */

/* Phone country flag Early access */
.Early-access .PhoneInput {
    margin-top: 1vw;
}
.Early-access .PhoneInputCountry {
    transform: scale(1.5);
    padding: 0px 10px;
}
.Early-access .PhoneInputInput {
    border: 0px solid;
    height: 2.2vw;
    border-radius: 10px;
    font-size: 1vw;
    padding-left: 10px;
}
/* End of phone coutry flag */

/* Phone country flag for clinical trials form */
.clinical-trials .PhoneInput {
    margin-top: 1vw;
}
.clinical-trials .PhoneInputCountry {
    transform: scale(1.5);
    padding: 0px 10px;
}
.clinical-trials .PhoneInputInput {
    border: 0px solid;
    height: 2.2vw;
    border-radius: 10px;
    font-size: 0.7vw;
    padding-left: 10px;
    background-color: #f8f9fb;
    font-family: "Montserrat";
}
.clinical-trials input {
    font-family: "Montserrat";
}
#age,
#day,
#month,
#year,
#venue,
#title {
    font-family: "Montserrat";
}
select {
    -webkit-padding-end: 20px;
    -moz-padding-end: 20px;
    -webkit-padding-start: 2px;
    -moz-padding-start: 2px;
    background-color: #f07575;
    background-position: center right;
    background-repeat: no-repeat;
    border: 1px solid #aaa;
    border-radius: 2px;
    color: #555;
    font-size: inherit;
    margin: 0;
    overflow: hidden;
    padding-top: 2px;
    padding-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 450px) {
    #age,
    #day,
    #month,
    #year,
    #venue,
    #title {
        width: 20vw !important ;
    }
    select {
        /* -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
        background-size: 12px;
        background-position: calc(100% - 20px) center;
        background-repeat: no-repeat;
        background-color: #efefef; */
        -webkit-appearance: button;
        -moz-appearance: button;
        -webkit-user-select: none;
        -moz-user-select: none;
        -webkit-padding-end: 20px;
        -moz-padding-end: 20px;
        -webkit-padding-start: 2px;
        -moz-padding-start: 2px;
        background-color: #f07575;
        background-position: center right;
        background-repeat: no-repeat;
        border: 1px solid #aaa;
        border-radius: 2px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        color: #555;
        font-size: inherit;
        margin: 0;
        overflow: hidden;
        padding-top: 2px;
        padding-bottom: 2px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    #title {
        height: 8vw !important;
    }
    .clinical-trials input {
        height: 8vw !important;
    }
    .clinical-trials .PhoneInput {
        margin-top: 3vw;
    }
    .waitlist {
        top: 25vw !important;
    }
}
/* End of clinical trials */

/* Phone country for DocMe */
.DocMe .PhoneInput {
    margin-top: 1vw;
}
.DocMe .PhoneInputCountry {
    transform: scale(1);
    padding: 0px 15px;
    margin-right: 0.8vw;
    background-color: white;
    border-radius: 10px;
}
.DocMe .PhoneInputInput {
    border: 0px solid;
    height: 2.5vw;
    border-radius: 10px;
    font-size: 1vw;
    padding-left: 10px;
}
.DocMe .PhoneInputCountryIconImg {
    /* width: 10px !important; */
}
.docmePartnerSlider .slick-dots li button:before {
    font-size: 1rem;
    color: #4F6E8C !important;
}
.docmePartnerSlider .slick-dots li.slick-active button:before {
    color: black !important;
}
.docmePartnerSlider .slick-list{
    margin-bottom: 2vw !important;
}
/* End of Phone country from DocMe */

/* Assesment card slider css */
.AssesmentCards .slick-slide{
    /* width: 14.5vw !important;
    margin-right: 12vw !important; */
}
/* End of Assesment card slider */
.contentForImage > img {
    width: 100%;
    height: auto;
}